@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  $nm-input-border-readonly: mat.get-color-from-palette($foreground, nm-input-border-readonly);
  $nm-input-color-readonly: mat.get-color-from-palette($foreground, nm-input-color-readonly);
  $nm-input-text: mat.get-color-from-palette($foreground, nm-input-text);

  .nm-chips-control {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .chips-div {
    display: flex;
    overflow-x: auto;
    gap: 8px;
    flex-direction: row;
    align-items: center;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .chip {
    overflow: visible !important;
  }

  .mat-chip {
    width: 100%;
    min-width: 41px;
    justify-content: center;
  }
}

:host {
  width: 100%;
}
